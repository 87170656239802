body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.news-chat-wrapper{
  padding: 20px;
}
.title-wrapper{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
}
.title-wrapper h2.ant-typography{
  margin-bottom: 0;
}

.newsChat-detailpage-wrapper{
  /* height: 100%; */
}
.title-wrapper{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
}
.title-wrapper h2.ant-typography{
  margin-bottom: 0;
}

.newsChat-subject{
  color: rgba(0, 0, 0, 0.65);
}
.newsChat-detailpage-wrapper .newsChat-description{
  margin: 20px 0;
  margin-bottom: 0;
}
.newsChat-image{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.newsChat-image p{
  font-weight: 500;
  font-size: 18px;
  margin-top: 20px;
}
.newsChat-wrapper .ant-calendar-picker-input.ant-input{
  padding: 4px;
  font-size: 13px;
}
/* .news-chat-wrapper .ant-table-row td:first-child,
.news-chat-wrapper .ant-table-row td:first-child a, */
.news-chat-wrapper .ant-table-row td:first-child:hover, .news-chat-wrapper .ant-table-row td:first-child:hover a {
  cursor: pointer !important;
  color: #40a9ff;
}
.news-chat-wrapper .ant-pagination{
  text-align: right;
  margin-top: 20px;
}
.box h3.ant-typography {
  font-size: 20px;
}
.ant-input-number {
  width: 100%!important;
}

.customer-fileupload .ant-upload-list-item-name{
  display: inline-block!important;
}
.customer-fileupload i.anticon-loading{
  display:none
}