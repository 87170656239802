.width-100 {
  width: 100%;
}
.amenity-checkboxes {
  margin: 10px !important;
}
.gallery-wrapper .ant-upload.ant-upload-select {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  background: #fafafa;
  border: 1px dashed #d9d9d9;
}
.add-restaurant-form {
  padding: 20px !important;
}
.gallery-wrapper .ant-upload {
  background: transparent;
  text-align: center;
  padding: 17px;
}
.gallery-wrapper .ant-upload-list-picture .ant-upload-list-item,
.ant-upload-list-picture-card .ant-upload-list-item {
  height: 90px !important;
  margin-right: 10px;
}
.gallery-wrapper .ant-upload-list.ant-upload-list-picture {
  display: flex;
}
.gallery-wrapper .ant-upload-list-item-progress,
.ant-upload-list-item-name {
  display: none !important;
}
.gallery-wrapper .ant-upload-list-item-info {
  min-width: 100px;
}
.gallery-wrapper .ant-upload-list-item.ant-upload-list-item-uploading {
  margin-right: 10px;
}
.gallery-wrapper .ant-upload-list-picture .ant-upload-list-item-thumbnail,
.gallery-wrapper .ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
.gallery-wrapper .ant-upload-list-picture .ant-upload-list-item-thumbnail img,
.gallery-wrapper
  .ant-upload-list-picture-card
  .ant-upload-list-item-thumbnail
  img {
  height: 70px !important;
}

.ant-tabs-tab-active.ant-tabs-tab,
.ant-tabs-nav .ant-tabs-tab:hover {
  color: #aeca36 !important;
}
.ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  background-color: #aeca36;
}

.image-col {
  padding: 1em;
}

.restaurant-profile-image {
  object-fit: contain;
  width: 100%;
  height: 50px;
}
h3.ant-typography,
.ant-typography h3 {
  margin-bottom: 0 !important;
}
.ant-input-number {
  width: 100%!important;
}
.restaurant-wrapper {
  padding: 20px;
}
.restaurant-wrapper.restaurant-member .ant-page-header-back {
  display: none;
}
.gallery-showcase-wrapper .ant-col{
  align-items: center;
  justify-content: center;
  display: flex;
}
.gallery-showcase-wrapper .ant-col img{
  max-width: 100%;
  width: 300px;
  height: 150px;
  max-height: 150px;
  object-fit: contain;
}
