.login-wrapper{
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-wrapper .login-form-wrapper{
  padding: 50px;
  border-radius: 5px;
  background: #f2f2f2;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.27);
  /* transform: translate3D(0,-1px,0) scale(1.02);
  transition: all .4s ease; */
}
.login-wrapper .login-form-wrapper .header{
  margin-bottom: 20px;
}
.login-wrapper img{
  width: 250px;
}
.login-wrapper .login-form-wrapper .body{
  margin-top: 40px;
  text-align: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}
.login-wrapper .login-form-wrapper .body .ant-input{
  margin-bottom: 10px;
  width: 100%;
}
.login-wrapper .login-form-wrapper hr{
  margin-bottom: 25px;
}
.login-wrapper .login-form-wrapper .body button{
  width: 50%;
  margin: 0 auto;
  margin-top: 10px;
}
.login-wrapper .login-form-wrapper .body .resend-otp{
  margin: 0;
  text-align: center;
  width: 100%;
  font-size: 12px;
}
