@import "~antd/dist/antd.css";
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,700,900");

#root {
  height: 100vh;
  font-family: "Montserrat";
}
.ant-menu.ant-menu-light.ant-menu-root.ant-menu-inline {
  border: none;
}
.content-wrapper.ant-layout-content {
  max-height: calc(100vh - 64px);
  overflow-y: auto;
  max-width: 100%;
  overflow-x: hidden;
}
.fullscreen {
  height: 100%;
  background: #f0f3f5;
}
img {
  max-width: 100%;
}
.d-flex {
  display: flex;
}
.box {
  border-radius: 5px;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
  display: block;
  /* margin-bottom: 1rem; */
  padding: 1rem;
  background: #fff;
}
.text-center {
  text-align: center;
}
.border-bottom {
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 0.5em;
}
.loader-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.callback-wrapper {
  width: 100%;
}
.callback-wrapper .loader-wrapper {
  height: 100vh;
}
.ant-table-row:nth-child(even),
.ant-table-thead > tr > th {
  background: #eeeeee;
}
.ant-table-row:nth-child(odd) {
  background: #f6f6f6;
}
.ant-form-vertical .ant-form-item {
  padding-bottom: 0;
  line-height: 1;
  margin-bottom: 16px;
}

/* EPISODE STAUS */
.ant-tag.IDLE {
  color: #2f54eb;
  background: #f0f5ff;
  border-color: #adc6ff;
}
.ant-tag.DRAFT {
  color: #fa8c16;
  background: #fff7e6;
  border-color: #ffd591;
}
.ant-tag.SCHEDULED,
.ant-tag.RECORDING,
.ant-tag.UPCOMING {
  color: #13c2c2;
  background: #e6fffb;
  border-color: #87e8de;
}
.ant-tag.LIVE,
.ant-tag.OPEN,
.ant-tag.LIVE_FINISHED,
.ant-tag.CONNECTED {
  color: #52c41a;
  background: #f6ffed;
  border-color: #b7eb8f;
}
.ant-tag.PUBLISHED {
  color: #1890ff;
  background: #e6f7ff;
  border-color: #91d5ff;
}
.ant-tag.UNPUBLISHED,
.ant-tag.DISCONNECTED,
.ant-tag.ENDED,
.ant-tag.ERROR {
  color: #f5222d;
  background: #fff1f0;
  border-color: #ffa39e;
}
/* END - EPISODE STAUS */

.ant-calendar-time-picker-btn {
  text-transform: capitalize;
}
.ant-calendar-ok-btn {
  text-transform: uppercase;
}
/* Upload */
.ant-upload-list-picture .ant-upload-list-item-name,
.ant-upload-list-picture-card .ant-upload-list-item-name {
  padding-left: 80px;
}
.ant-upload-list-picture .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
.ant-upload-list-picture .ant-upload-list-item-thumbnail img,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  width: 80px;
}
/* END - Upload */

/* FONT WEIGHT */
.fw-100 {
  font-weight: 100;
}
.fw-200 {
  font-weight: 200;
}
.fw-300 {
  font-weight: 300;
}
.fw-400 {
  font-weight: 400;
}
.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}
/* END - FONT WEIGHT */

/* .App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

/* 404 */
.wrapper-404 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 100%;
}
.wrapper-404 .title-404 {
  font-size: 10rem;
  font-weight: 700;
}
.wrapper-404 .subtitle-404 {
  font-weight: 700;
  font-size: 200%;
  margin-bottom: 2rem;
  line-height: 1.2;
}
.wrapper-404 .description-404 {
  margin-bottom: 10rem;
}
/* END - 404 */
.avatar-uploader > .ant-upload {
  width: 100%;
}

.geosuggest {
  /* font-size: 18px; */
  /* font-size: 1rem; */
  position: relative;
  /* width: 50%; */
  /* margin: 1em auto; */
  text-align: left;
}
.geosuggest__input {
  width: 100%;
  /* border: 1px solid transparent; */
  /* box-shadow: 0 0 1px #3d464d; */
  /* padding: 0.5em 1em; */
  /* -webkit-transition: border 0.2s, box-shadow 0.2s; */
  /* transition: border 0.2s, box-shadow 0.2s; */
}
/* .geosuggest__input:focus {
  border-color: #267dc0;
  box-shadow: 0 0 0 transparent;
} */
.geosuggest__suggests {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 25em;
  padding: 0;
  margin-top: -1px;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  border-top-width: 0;
  overflow-x: hidden;
  overflow-y: auto;
  list-style: none;
  z-index: 5;
  -webkit-transition: max-height 0.2s, border 0.2s;
  transition: max-height 0.2s, border 0.2s;
}
.geosuggest__suggests--hidden {
  max-height: 0;
  overflow: hidden;
  border-width: 0;
}

/**
 * A geosuggest item
 */
.geosuggest__item {
  font-size: 18px;
  font-size: 1rem;
  padding: 0.5em 0.65em;
  cursor: pointer;
}
.geosuggest__item:hover,
.geosuggest__item:focus {
  background: #f5f5f5;
}
.geosuggest__item--active {
  background: #267dc0;
  color: #fff;
}
.geosuggest__item--active:hover,
.geosuggest__item--active:focus {
  background: #ccc;
}
.geosuggest__item__matched-text {
  font-weight: bold;
}

.ant-btn-primary {
  background-color: #aeca36;
  border-color: #aeca36;
}
.ant-btn-primary:focus, .ant-btn-primary:hover{
  background-color: #97b31d;
  border-color: #97b31d;
  /* color: #aeca36 */
}
.ant-menu-item-selected > a, .ant-menu-item-selected > a:hover, .ant-menu-item > a:hover{
  color: #aeca36;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
  background-color: #aeca360d;
}
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after{
  border-right: 3px solid #aeca36;
}

.ant-table-bordered .ant-table-thead > tr > th{
  white-space: nowrap;
}
.thumb-wrapper .ant-col.ant-form-item-control-wrapper{
  width: 100%;
  margin: 0 auto;
  padding: 0 50px;
}
